import axios from 'axios'
import Module from "./Module"
import ChartColors from '../ChartColors'
import FormatDate from '../FormatDate'
import WebsocketService from '../WebsocketService'

export default class Product { 

    constructor(object){
        this.build(object)        
    }

    initializeWebSocketListener() {

        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);

        WebsocketService.sendMessage({
            command: 'subscribe',
            resource: `/api/products/${this.barCode}`,
        });

        WebsocketService.addListener(async (type, data) => {
            if (type === 'message' && data.resource === `/api/products/${this.barCode}`) {
                await this.retrieveEntity();
            }
        });
    }
    
    unsubscribeWebSocket() {
        WebsocketService.sendMessage({
            command: 'unsubscribe',
            resource: `/api/products/${this.barCode}`,
        });
    }

    build(object){
        object.id ? this.id = object.id : this.id = null
        object.barCode ? this.barCode = object.barCode : this.barCode = null
        object.brand ? this.brand = object.brand : this.brand = null
        object.counts ? this.counts = object.counts : this.counts = [];
        object.totalCount !== undefined ? this.totalCount = object.totalCount : this.totalCount = 'N/A';
        object.imageUrl ? this.imageUrl = object.imageUrl : this.imageUrl = null;
        object.minProduct !== undefined ? this.minProduct = object.minProduct : this.minProduct = 'N/A';
        if(object.modules){
            var modules = []
            object.modules.forEach((module) => {
                modules.push(new Module(module))
            })
            this.modules = modules
        }
        object.netWeight ? this.netWeight = object.netWeight : this.netWeight = 0
        object.reference ? this.reference = object.reference : this.reference = null
        object.weight ? this.weight = object.weight : this.weight = 0
        object.section ? this.section = object.section : this.section = -1
        object.isSensitive ? this.isSensitive = object.isSensitive : this.isSensitive = false
        object.expiringOffset ? this.expiringOffset = object.expiringOffset : this.expiringOffset = 60

        this.events = {
            labels : [],
            datasets: [],
        };
        const now = new Date()
        const midnight = new Date(now.setHours(0,0,0,0));
        this.filters = [{
            barCode : this.barCode,
            resource: '/api/products/'+this.barCode,
            timePeriod: 'quarterDay',
            'createdAt[before]' : midnight,
            'createdAt[after]' : new Date(midnight.getFullYear(), midnight.getMonth(), midnight.getDate() - 7, midnight.getHours(), midnight.getMinutes()),
            itemsPerPage: 1000
        },
        {
            barCode : this.barCode,
            resource: '/api/products/'+this.barCode,
            timePeriod: 'quarterDay',
            'createdAt[before]' : new Date(midnight.getFullYear(), midnight.getMonth(), midnight.getDate() - 7, midnight.getHours(), midnight.getMinutes()),
            'createdAt[after]' : new Date(midnight.getFullYear(), midnight.getMonth(), midnight.getDate() - 14, midnight.getHours(), midnight.getMinutes()),
            itemsPerPage: 1000
        }];
    }

    serialize(){
        return {
            brand: this.brand,
            imageUrl: this.imageUrl,
            netWeight: this.netWeight,
            reference: this.reference,
            weight: this.weight,
            minProduct: this.minProduct,
            isSensitive : this.isSensitive,
            expiringOffset : parseInt(this.expiringOffset)
        }
    }

    async retrieveEntity() {
        if(this.barCode){
            await axios
            .get(process.env.VUE_APP_API_BASE_PATH+'/products/'+this.barCode,
            {
                withCredentials: true
            })
            .then(response => {
                if(response.status == 200){
                    this.build(response.data)
                }
            })
        }
    }

    async getEvents() {
        let dataSetLength = 0;
        for(const [index, filters] of this.filters.entries()){
            let getParameters = '?'
            Object.keys(filters).forEach((element,index) => {
                if(element !== 'barCode'){
                    if(filters[element] instanceof Date && !isNaN(filters[element].valueOf())){
                        getParameters += element+"="+filters[element].toISOString()
                    } else {
                        getParameters += element+"="+filters[element]
                    }
                    if(index != Object.keys(filters).length - 1){
                        getParameters += "&"
                    }
                }
            })
            await axios
            .get(process.env.VUE_APP_API_BASE_PATH+'/events'+getParameters,
            {
                withCredentials: true
            })
            .then(response => {
                if(response.status == 200){
                    let data = response.data['hydra:member'].sort((a,b) => {return new Date(b.createdAt).getTime() - new Date(a.createProduct).getTime() > 1 ? 1 : -1});
                    let dataset = []
                    let labels = []
                    var date = null;
                    var dateNumber = 0;
                    data.forEach((element) => {
                        dataset.push(element.totalProducts)
                        if(this.filters[0].barCode !== this.filters[1].barCode){
                            labels.push(FormatDate(element.createdAt, 'datetime'))
                        } else {
                            if(FormatDate(element.createdAt, 'date') !== date){
                                dateNumber++
                                date = FormatDate(element.createdAt, 'date')
                            }
                            labels.push("Jour "+dateNumber+" "+ FormatDate(element.createdAt, 'time'))
                        }
                    })
                    dataSetLength < data.length ? this.events.labels = labels : null
                    dataSetLength < data.length ? dataSetLength = data.length : null
                    this.events.datasets[index] = {
                        type: 'line',
                        label: !index ? "Référence : Total Produits" : "Comparaison : Total Produits",
                        backgroundColor: ChartColors[500*index].hex,
                        data: dataset
                    }
                }
            })
        }
    }

    async getEventsExtract(filtersIndex) {
        let getParameters = '?'
        Object.keys(this.filters[filtersIndex]).forEach((element,index) => {
            if(element !== 'barCode'){
                if(this.filters[filtersIndex][element] instanceof Date && !isNaN(this.filters[filtersIndex][element].valueOf())){
                    getParameters += element+"="+this.filters[filtersIndex][element].toISOString()
                } else {
                    getParameters += element+"="+this.filters[filtersIndex][element]
                }
                if(index != Object.keys(this.filters[filtersIndex]).length - 1){
                    getParameters += "&"
                }
            }
        })
        await axios
        .get(process.env.VUE_APP_API_BASE_PATH+'/events_extract'+getParameters,
        {
            withCredentials: true
        })
        .then(response => {
            if(response.status == 201){
                const link = document.createElement('a')
                let path = ""
                let basePathArray = process.env.VUE_APP_API_BASE_PATH.split('/api')
                if(basePathArray.length > 2){
                    let i = 0
                    for(i; i < basePathArray.length - 2; i++){
                        path += basePathArray[i]+'/api'
                    }
                    path += basePathArray[i]
                } else {
                    path = basePathArray[0]
                }

                console.log(path)
                link.href = path+response.data.fileUri
                link.target = "_blank"
                link.click()
                URL.revokeObjectURL(link.href)
            }
        })
    }


    getLocations(){
        var locations = []
        this.modules.forEach(module => {
            var index = locations.find(element => element === module.parentLinear.getLocation())
            if(!index){
                locations.push({
                    linear: module.parentLinear.getLocation(),
                    modules: [module.moduleOrder]
                })
            } else {
                locations[index].modules.push(module.moduleOrder)
            }
        })
        return locations
    }
    
    getLocationsForDisplay(){
        var displayLocations = []
        var locations = this.getLocations()
        locations.forEach(location => {
            displayLocations.push(
                'Allée :'+ location.linear.aisle +' - Rang :'+ location.linear.position +' - Étage :'+ location.linear.storey +' - Section :'+ location.modules.toString()
            )
        })
        return displayLocations
    }

    getTotalCount(){
        var total = 0;
        this.modules.forEach(module => {
            total += module.productCount
        })
        return total
    }

    async associateProduct(modules){
        await axios
        .patch(process.env.VUE_APP_API_BASE_PATH+'/products/'+this.barCode+'/associate', 
            modules,
            {
                withCredentials: true,
                headers: { 'Content-Type' : 'application/merge-patch+json' } 
            })
        .then(response => {
            this.build(response.data)
        })
    }

    async updateProduct(){
        await axios
        .patch(process.env.VUE_APP_API_BASE_PATH+'/products/'+this.barCode, 
            {
                weight: parseInt(this.weight),
                netWeight: parseInt(this.netWeight),
                reference: this.reference,
                brand: this.brand,
                imageUrl: this.imageUrl,
                section: parseInt(this.section) !== -1 ? this.section : "",
                minProduct: parseInt(this.minProduct),
                isSensitive : this.isSensitive,
                expiringOffset : parseInt(this.expiringOffset)
            },
            {
                withCredentials: true,
                headers: { 'Content-Type' : 'application/merge-patch+json' } 
            })
        .then(response => {
            this.build(response.data)
        })
    }

    async createProduct(){
        await axios
        .post(process.env.VUE_APP_API_BASE_PATH+'/products', 
            {
                barCode: this.barCode,
                weight: parseInt(this.weight),
                netWeight: parseInt(this.netWeight),
                reference: this.reference,
                brand: this.brand,
                imageUrl: this.imageUrl,
                section: parseInt(this.section) !== -1 ? this.section : "",
                minProduct: parseInt(this.minProduct),
                isSensitive : this.isSensitive,
                expiringOffset : parseInt(this.expiringOffset)
            },
            {
                withCredentials: true,
            })
        .then(response => {
            this.build(response.data)
            console.log('creation du produit');
        })
    }
}

